<template>
  <div>
    <div v-if="!isSuccess">
      <ValidationObserver v-slot="{ handleSubmit }">
        <loading
          :active.sync="isSubmitted"
          color="#ff9300"
        />
        <form
          class="w-full max-w-lg mx-auto pt-24 mb-16 px-3"
          style="min-height: 70vh;"
          autocomplete="off"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full">
              <h2 class="text-3xl font-semibold py-3 px-3 mb-3">
                Join graduated as an employer
              </h2>
              <p class="py-3 px-3 mb-3">
                Advertise your graduate jobs today
              </p>
            </div>

            <div class="w-full md:w-1/2 px-3 pt-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="firstName"
              >First name</label>
              <ValidationProvider
                v-slot="{ errors }"
                name="first name"
                rules="required|alpha"
                mode="eager"
              >
                <input
                  v-model="contact.firstName"
                  class="appearance-none block w-full text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  type="text"
                  placeholder="Jane"
                >
                <p class="text-red-500 text-xs">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
            <div class="w-full md:w-1/2 px-3 pt-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >Last name</label>

              <ValidationProvider
                v-slot="{ errors }"
                name="last name"
                rules="required|alpha"
                mode="eager"
              >
                <input
                  v-model="contact.lastName"
                  class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Smith"
                >
                <p class="text-red-500 text-xs">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>

            <div class="w-full md:w-1/2 px-3 pt-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-password"
              >Work email</label>

              <ValidationProvider
                v-slot="{ errors }"
                name="email"
                rules="required|email"
                mode="eager"
              >
                <input
                  v-model="contact.email"
                  class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="email"
                  placeholder="jane@example.com"
                >
                <p class="text-red-500 text-xs">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
            <div class="w-full md:w-1/2 px-3 pt-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-phone"
              >Phone</label>

              <ValidationProvider
                v-slot="{ errors }"
                name="phone"
                :rules="{
                  required: true,
                  regex: /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/,
                }"
                mode="eager"
              >
                <input
                  id="grid-phone"
                  v-model="contact.phone"
                  class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="0400111222"
                >
                <p class="text-red-500 text-xs">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>

            <div class="w-full md:w-1/2 px-3 pt-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-company-name"
              >Company name</label>

              <ValidationProvider
                v-slot="{ errors }"
                name="companyName"
                rules="required"
                mode="eager"
              >
                <input
                  v-model="contact.companyName"
                  class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Acme Development"
                >
                <p class="text-red-500 text-xs">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
            <div class="w-full md:w-1/2 px-3 pt-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-company-website"
              >Company website</label>

              <ValidationProvider
                v-slot="{ errors }"
                name="companyWebsite"
                :rules="{
                  required: true,
                  regex: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
                }"
                mode="eager"
              >
                <input
                  id="grid-phone"
                  v-model="contact.companyWebsite"
                  class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="www.acmedev.io"
                >
                <p class="text-red-500 text-xs">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>

            <div class="w-full px-3 pt-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-password"
              >Message</label>
              <ValidationProvider
                v-slot="{ errors }"
                name="message"
                rules="required|max:1000"
                mode="eager"
              >
                <textarea
                  v-model="contact.message"
                  class="no-resize appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-32 resize-none"
                  maxlength="1000"
                  placeholder="We'd like to post some roles on the site."
                />

                <p class="text-red-500 text-xs">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
          </div>
          <div class="w-full text-right">
            <button
              class="shadow bg-orange-500 hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              type="submit"
            >
              Send application
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div
      v-if="isSuccess"
      class="w-full max-w-lg mx-auto pt-24 mb-16 px-3"
      style="min-height: 40vh;"
    >
      <h2 class="text-3xl font-semibold py-3 px-3 mb-3">
        We received your application
      </h2>

      <p class="py-3 px-3 mb-3">
        Thanks for reaching out to us. We'll be in touch shortly.
      </p>
    </div>
  </div>
</template>

<script>
import { API } from "@aws-amplify/api";
import Loading from "vue-loading-overlay";
import { join } from "@/graphql/mutations";

export default {
  name: "Join",
  components: {
    Loading,
  },
  data() {
    return {
      contact: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        companyName: "",
        companyWebsite: "",
        message: "",
      },
      isSubmitted: false,
      isSuccess: false,
    };
  },
  methods: {
    async onSubmit() {
      this.isSubmitted = true;

      try{
        await API.graphql({
          query: join,
          variables: { input: this.contact },
          authMode: "AWS_IAM",
        });
        this.isSubmitted = false;
        this.isSuccess = true;
      } 
      catch(error) {
        console.log(error);

        this.isSubmitted = false;
      }
    },
  },
};
</script>
